import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from './Modal';

const riverEcosystemsData = [
    {
        title: 'Лотические экосистемы (текучие воды)',
        image: './img/lotic.jpg', // Замените на путь к вашему изображению
        description: 'Реки, ручьи, потоки.  Характеризуются течением воды.',
    },
    {
        title: 'Лентические экосистемы (стоячие воды)',
        image: './img/lentic.jpg', // Замените на путь к вашему изображению
        description: 'Озера, пруды, болота.  Вода практически неподвижна.',
    },
    {
        title: 'Эстуарии (устья рек)',
        image: './img/river_estuary.jpg', // Замените на путь к вашему изображению
        description: 'Переходная зона между рекой и морем.  Смешение пресной и соленой воды.',
    },

];

const RiverEcosystemCard = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [modalData, setModalData] = useState(null);

    const openModal = (slide) => {
        setModalIsOpen(true);
        setModalData(slide);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalData(null);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    return (
        <div className="ecosystem-card">
            <h2>Речные экосистемы</h2>
            <Slider {...settings}>
                {riverEcosystemsData.map((slide, index) => (
                    <div key={index} className="slider-item" onClick={() => openModal(slide)}>
                        <img src={slide.image} alt={slide.title} /> 
                        <h3>{slide.title}</h3>                       
                        <p>{slide.description}</p>
                    </div>
                ))}
            </Slider>
            <Modal isOpen={modalIsOpen} onClose={closeModal} {...modalData} />
        </div>
    );
};

export default RiverEcosystemCard;
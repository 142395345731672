import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../css/OceanEcosystems.css';
import NerticEcosystemCard from './NerticEcosystemCard';
import PelagicEcosystemCard from './PelagicEcosystemCard';
import BenthicEcosystemCard from './BenthicEcosystemCard';
import MarineEcosystemCard from './MarineEcosystemCard';
import RiverEcosystemCard from './RiverEcosystemCard';
import LakeEcosystemCard from './LakeEcosystemCard';
import PondEcosystemCard from './PondEcosystemCard';
import AdvertisingCard from './AdvertisingCard';
import LampaTourCard from './LampaTourCard';

const OceanEcosystems = () => {    
  
    return (
      <div className="ocean-ecosystems" id="oceanic-ecosystems">
        <div className="header">          
          <h1>Океанические экосистемы</h1>
          {/* <img src='./img/ocean.png' alt="Океан" /> */}
        </div>
        <div className="ecosystem-cards">
          <NerticEcosystemCard />
          <PelagicEcosystemCard />
          <AdvertisingCard />
          <BenthicEcosystemCard />
          <MarineEcosystemCard />
          <RiverEcosystemCard />
          <LakeEcosystemCard />
          <PondEcosystemCard />          
          <LampaTourCard />        
        </div>
      </div>
    );
  };
  
  export default OceanEcosystems;
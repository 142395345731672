import React, { Component } from 'react';

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlideIndex: 0,
    };

    this.slidesData = [
      {
        image: './img/slide-1.jpg',
        alt: 'Slide 1',
        text: 'Подводный мир – твое новое приключение!',
      },       
      {
        image: './img/slide-2.jpg',
        alt: 'Slide 2',
        text: 'Открой подводный мир: погрузись в удивительное!',
      },      
      {
        image: './img/slide-3.jpg',
        alt: 'Slide 3',
        text: 'Подводные тайны ждут: исследуй их с нами!',
      },           
      {
        image: './img/slide-4.jpg',
        alt: 'Slide 4',
        text: 'Морской мир: исследуй, наслаждайся, погружайся!',
      },               
      // Добавьте больше слайдов
    ];

    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState({
        currentSlideIndex: (this.state.currentSlideIndex + 1) % this.slidesData.length,
      });
    }, 5000); // 3 секунды
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  nextSlide() {
    this.setState({
      currentSlideIndex: (this.state.currentSlideIndex + 1) % this.slidesData.length,
    });
  }

  prevSlide() {
    this.setState({
      currentSlideIndex: (this.state.currentSlideIndex - 1 + this.slidesData.length) % this.slidesData.length,
    });
  }


  render() {
    return (
      <div className="slider-container">
        {this.slidesData.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === this.state.currentSlideIndex ? 'active' : ''}`}
          >
            <div className="img-container">
              <img src={slide.image} alt={slide.alt} />
              <p>{slide.text}</p>
            </div>
          </div>
        ))}
        <div className="nav-buttons">
          <button className="prev-btn" onClick={this.prevSlide}>
            <span>prev</span>
          </button>
          <button className="next-btn" onClick={this.nextSlide}>
            <span>next</span>
          </button>
        </div>        
      </div>
    );
  }
}

export default Slider;
import React from 'react';

const lampaTourData = [
  {
    title: 'Египет: ваше путешествие мечты.',
    image: './img/lampatur.jpg',
    description: 'Почувствуйте магию Египта: экскурсии, которые соединят историю, приключения и незабываемые моменты.',
  },
];

const handleSiteClik = () => {
  window.open('https://lampatour.com/'); 
}
  


const LampaTourCard = () => {
  const { title, image, description } = lampaTourData[0]; // Берем первый элемент массива

  return (
    <div className="ecosystem-card" onClick={handleSiteClik}>
      <h2>Все экскурсии в Египте в одном месте</h2>
      <div className="slider-item"> {/* Или используйте другой класс */}
        <img src={image} alt={title} />
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default LampaTourCard;
import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from './Modal';

const marineEcosystemsData = [
    {
        title: 'Эстуарии (устья рек)',
        image: './img/estuary.jpg', // Замените на путь к вашему изображению
        description: 'Переходная зона между пресной и соленой водой. Высокая биопродуктивность.',
    },
    {
        title: 'Солончаки',
        image: './img/saltmarsh.jpg', // Замените на путь к вашему изображению
        description: 'Заболоченные участки, затопляемые во время приливов. Галофитная растительность.',
    },
    {
        title: 'Мангровые леса',
        image: './img/mangrove.jpg', // Замените на путь к вашему изображению
        description: 'Прибрежные леса из мангровых деревьев. Важная роль в защите берегов.',
    },
    {
        title: 'Лагуны',
        image: './img/lagoon.jpg', // Замените на путь к вашему изображению
        description: 'Мелководные бассейны, отделенные от моря песчаными косами или рифами.',
    },
    {
        title: 'Заливы',
        image: './img/bay.jpg', // Замените на путь к вашему изображению
        description: 'Части океана, вдающиеся в сушу.',
    },
    {
        title: 'Морские луга (заросли морских трав)',
        image: './img/seagrass.jpg', // Замените на путь к вашему изображению
        description: 'Важны для поддержания биоразнообразия.',
    },
    {
        title: 'Кельповые леса (бурые водоросли)',
        image: './img/kelpforest.jpg', // Замените на путь к вашему изображению
        description: 'Обеспечивают укрытие и пищу для морских животных.',
    },
];

const MarineEcosystemCard = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [modalData, setModalData] = useState(null);

    const openModal = (slide) => {
        setModalIsOpen(true);
        setModalData(slide);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalData(null);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    return (
        <div className="ecosystem-card">
            <h2>Морские экосистемы (включая моря и заливы)</h2>
            <Slider {...settings}>
                {marineEcosystemsData.map((slide, index) => (
                    <div key={index} className="slider-item" onClick={() => openModal(slide)}>
                        <img src={slide.image} alt={slide.title} />
                        <h3>{slide.title}</h3>                        
                        <p>{slide.description}</p>
                    </div>
                ))}
            </Slider>
            <Modal isOpen={modalIsOpen} onClose={closeModal} {...modalData} />
        </div>
    );
};

export default MarineEcosystemCard;
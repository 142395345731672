import React, { useState, useEffect } from "react";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`${isScrolled ? "scrolled" : ""}`}>
      <div className='container'>
        <img className='logo' src='./img/LOGO.svg' alt='logo' />
        <nav>
          <ul>
            <a href="#oceanic-ecosystems"><li>ОКЕАНИЧЕСКИЕ ЭКОСИСТЕМЫ</li></a>
            <li>BLOG</li>
            <li>GALLERY</li>
            <li>CONTACTS</li>
          </ul>
        </nav>
      </div>     
    </header>
  );
}

export default Header;



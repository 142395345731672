import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from './Modal';

const lakeEcosystemsData = [
    {
        title: 'Олиготрофные озера',
        image: './img/oligotrophic_lake.jpg', // Замените на путь к вашему изображению
        description: 'Бедные питательными веществами, обычно глубокие и чистые.',
    },
    {
        title: 'Эвтрофные озера',
        image: './img/eutrophic_lake.jpg', // Замените на путь к вашему изображению
        description: 'Богатые питательными веществами, обычно мелководные и мутные.',
    },
    {
        title: 'Дистрофные озера',
        image: './img/dystrophic_lake.jpg', // Замените на путь к вашему изображению
        description: 'Богатые органическим веществом, кислые, с низким содержанием кислорода.',
    },
    {
        title: 'Карстовые озера',
        image: './img/karst_lake.jpg', // Замените на путь к вашему изображению
        description: 'Образуются в карстовых пещерах.',
    },
    {
        title: 'Ледниковые озера',
        image: './img/glacial_lake.jpg', // Замените на путь к вашему изображению
        description: 'Образуются после таяния ледников.',
    },
    {
        title: 'Кратерные озера',
        image: './img/crater_lake.jpg', // Замените на путь к вашему изображению
        description: 'Образуются в кратерах вулканов.',
    },
];

const LakeEcosystemCard = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [modalData, setModalData] = useState(null);

    const openModal = (slide) => {
        setModalIsOpen(true);
        setModalData(slide);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalData(null);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    return (
        <div className="ecosystem-card">
            <h2>Озерные экосистемы</h2>
            <Slider {...settings}>
                {lakeEcosystemsData.map((slide, index) => (
                    <div key={index} className="slider-item" onClick={() => openModal(slide)}>
                        <img src={slide.image} alt={slide.title} />
                        <h3>{slide.title}</h3>                        
                        <p>{slide.description}</p>
                    </div>
                ))}
            </Slider>
            <Modal isOpen={modalIsOpen} onClose={closeModal} {...modalData} />
        </div>
    );
};

export default LakeEcosystemCard
import React from 'react';

const advertisingData = [
  {
    title: 'Превращаем идеи в сайты',
    image: './img/web-development.jpg',
    description: 'Профессиональная веб-разработка для вашего бизнеса. Индивидуальный подход, качественный результат.',
  },
];

const handleMySiteClik = () => {
  window.open('https://anton-purgin-portfolio.ru/'); 
}
  


const AdvertisingCard = () => {
  const { title, image, description } = advertisingData[0]; // Берем первый элемент массива

  return (
    <div className="ecosystem-card" onClick={handleMySiteClik}>
      <h2>Веб-решения под ключ</h2>
      <div className="slider-item"> {/* Или используйте другой класс */}
        <img src={image} alt={title} />
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default AdvertisingCard;
import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Slider from './components/Slider'
import OceanEcosystems from './components/OceanEcosystems'

export default function App() {
  return (
    <div className='wrapper'>
      <Header />
      <Slider />   
      <OceanEcosystems />   
      <Footer />
    </div>
  )
}

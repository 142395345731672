import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from './Modal';

const pondEcosystemsData = [
    {
        title: 'Естественные пруды',
        image: './img/natural_pond.jpg', // Замените на путь к вашему изображению
        description: 'Болотные пруды, пруды в руслах рек.  Образуются естественным путем.',
    },
    {
        title: 'Искусственные пруды',
        image: './img/artificial_pond.jpg', // Замените на путь к вашему изображению
        description: 'Рыбоводческие пруды, декоративные пруды.  Созданы человеком.',
    },
];

const PondEcosystemCard = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [modalData, setModalData] = useState(null);

    const openModal = (slide) => {
        setModalIsOpen(true);
        setModalData(slide);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalData(null);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    return (
        <div className="ecosystem-card">
            <h2>Прудовые экосистемы</h2>
            <Slider {...settings}>
                {pondEcosystemsData.map((slide, index) => (
                    <div key={index} className="slider-item" onClick={() => openModal(slide)}>
                        <img src={slide.image} alt={slide.title} /> 
                        <h3>{slide.title}</h3>                       
                        <p>{slide.description}</p>
                    </div>
                ))}
            </Slider>
            <Modal isOpen={modalIsOpen} onClose={closeModal} {...modalData} />
        </div>
    );
};

export default PondEcosystemCard;
import React, {useState} from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from './Modal';

const pelagicEcosystemsData = [
  {
    title: 'Эпипелагическая зона (верхний слой)',
    image: './img/epipelagic.jpg', // Замените на путь к вашему изображению
    description: 'Хорошо освещенная зона, где происходит основной фотосинтез.  Богата жизнью.',
  },
  {
    title: 'Мезопелагическая зона (сумеречная зона)',
    image: './img/mesopelagic.jpg', // Замените на путь к вашему изображению
    description: 'Свет ослабевает, многие организмы обладают биолюминисценцией.',
  },
  {
    title: 'Батипелагическая зона (глубоководная зона)',
    image: './img/bathypelagic.jpg', // Замените на путь к вашему изображению
    description: 'Полное отсутствие света, высокое давление, низкая температура.  Уникальная фауна.',
  },
  {
    title: 'Абиссальная зона (глубоководная равнина)',
    image: './img/abyssal.jpg', // Замените на путь к вашему изображению
    description: 'Самая глубокая часть океана, с экстремальными условиями.',
  },
  {
    title: 'Хадальная зона (океанические желоба)',
    image: './img/hadal.jpg', // Замените на путь к вашему изображению
    description: 'Находится в океанических желобах, экстремальное давление и низкие температуры.',
  },
];

const PelagicEcosystemCard = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

    const [modalData, setModalData] = useState(null);

    const openModal = (slide) => {
        setModalIsOpen(true);
        setModalData(slide);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalData(null);
    };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  return (
    <div className="ecosystem-card">
      <h2>Пелагические экосистемы (открытый океан)</h2>
      <Slider {...settings}>
        {pelagicEcosystemsData.map((slide, index) => (
          <div key={index} className="slider-item" onClick={() => openModal(slide)}>
            <img src={slide.image} alt={slide.title} />
            <h3>{slide.title}</h3>            
            <p>{slide.description}</p>
          </div>
        ))}
      </Slider>
      <Modal isOpen={modalIsOpen} onClose={closeModal} {...modalData} />
    </div>
  );
};

export default PelagicEcosystemCard;
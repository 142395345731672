import React, {useState} from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from './Modal';

const benthicEcosystemsData = [
    {
        title: 'Коралловые рифы',
        image: './img/coralreef.jpg', // Замените на путь к вашему изображению
        description: 'Биогенные структуры, созданные коралловыми полипами.  Высокое биоразнообразие.',
    },
    {
        title: 'Гидротермальные источники',
        image: './img/hydrothermal.jpg', // Замените на путь к вашему изображению
        description: 'Подводные источники горячей воды, богатые химическими веществами.  Уникальные экосистемы.',
    },
    {
        title: 'Глубоководные равнины',
        image: './img/abyssalplain.jpg', // Замените на путь к вашему изображению
        description: 'Обширные равнины на дне океана, с низким биоразнообразием, но высокой биомассой.',
    },
    {
        title: 'Континентальный склон',
        image: './img/continental_slope.jpg', // Замените на путь к вашему изображению
        description: 'Крутой склон, соединяющий шельф с абиссальной равниной.  Сложная топография.',
    },
];

const BenthicEcosystemCard = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [modalData, setModalData] = useState(null);

    const openModal = (slide) => {
        setModalIsOpen(true);
        setModalData(slide);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalData(null);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    return (
        <div className="ecosystem-card">
            <h2>Бентосные экосистемы (донные)</h2>
            <Slider {...settings}>
                {benthicEcosystemsData.map((slide, index) => (
                    <div key={index} className="slider-item" onClick={() => openModal(slide)}>
                         <img src={slide.image} alt={slide.title} />
                        <h3>{slide.title}</h3>                       
                        <p>{slide.description}</p>
                    </div>
                ))}
            </Slider>
            <Modal isOpen={modalIsOpen} onClose={closeModal} {...modalData} />
        </div>
    );
};

export default BenthicEcosystemCard;
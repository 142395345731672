import React from 'react';
import '../css/Modal.css'; // Создайте файл Modal.css для стилей



const Modal = ({ isOpen, onClose, title, image, descriptionall }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <span className="modal-close" onClick={onClose}>&times;</span>
        <h2>{title}</h2>
        <img src={image} alt={title} />
        <p>{descriptionall}</p>
      </div>
    </div>
  );
};

export default Modal;